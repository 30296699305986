import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const locales = process.env.REACT_APP_LOCALES?.split(',') || [];

const origin =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: locales[0],
    supportedLngs: locales,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${origin}/translation/{{lng}}`,
    },
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
  });

export default i18next;
